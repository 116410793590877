import { HandleableError } from "@context-providers/error-boundary/error-types";
import * as Sentry from "@sentry/react";

// Extra tags we send to Sentry for easy filtering in the Sentry UI
// This way we can find the responsible team more easily
type SentryTags = {
  section: "staging-area" | "members-list" | "teams-list" |
    "analytics" | "projects-list" | "project-details" | "groups-list" |
    "groups-members" | "groups-projects" | "workspace-settings" |
    "account-settings" | "user-profile" | "other" ;
};

interface Props {
  /** The actual error object */
  error: HandleableError;

  /** The error title */
  title?: string;

  /** Additional data to be attached to error */
  data?: Record<string, unknown>;
}

/**
 * Get the application section (context) where the error was triggered.
 */
function getSectionTag(): SentryTags["section"] {
  const pathName = window.location.pathname;
  if ((`${pathName}/`).includes("/data-management/")) {
    return "staging-area";
  }
  if ((`${pathName}/`).includes("/users/members/")) {
    return "members-list";
  }
  if ((`${pathName}/`).includes("/users/teams/")) {
    return "teams-list";
  }
  if ((`${pathName}/`).includes("/analytics/workspace/")) {
    return "analytics";
  }
  if ((`${pathName}/`).includes("/projects/active/")) {
    return "projects-list";
  }
  if ((`${pathName}/`).includes("/settings/general/")) {
    return "workspace-settings";
  }
  if (/^\/[^/]+\/groups\/?$/.test(pathName)) {
    return "groups-list";
  }
  if (/^\/projects\/[^/]+\/details\/?$/.test(pathName)) {
    return "project-details";
  }
  if (/^\/[^/]+\/groups\/[^/]+\/members\/?$/.test(pathName)) {
    return "groups-members";
  }
  if (/^\/[^/]+\/groups\/[^/]+\/projects\/?$/.test(pathName)) {
    return "groups-projects";
  }
  if (/^\/[^/]+\/users\/members\/[^/]+\/account\/?$/.test(pathName)) {
    return "account-settings";
  }
  if (/^\/[^/]+\/users\/members\/[^/]+\/profile\/?$/.test(pathName)) {
    return "user-profile";
  }
  return "other";
}

/**
 * Capturing Errors and Events in Sentry.
 * A simple text should be passed as message, additional data should be passed as data
 * https://github.com/getsentry/sentry-javascript/issues/1607#issuecomment-825887112
 * https://github.com/getsentry/sentry-react-native/issues/1033#issuecomment-2143619805
 */
export function sentryCaptureError({ error, title, data }: Props): void {
  const tags: SentryTags = {
    section: getSectionTag(),
  };

  // Avoid <unknown> as the issue name
  const errorObject = typeof error === "string" ? new Error(error) : error;

  Sentry.captureException(errorObject, (scope) => {
    if (title) {
      // Set transaction name instead of the default current uri, which is useless
      // this way it's easier see the specific problem in the list and filter specific problems/errors
      scope.setTransactionName(title);
      scope.addBreadcrumb({
        type: "error",
        category: "error",
        level: "error",
        message: title,
      });
    }
    if (data) {
      scope.setContext("extra-data", data);
    }
    scope.setTags(tags);
    return scope;
  });
}
