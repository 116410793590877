import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { SphereLabel } from "@components/common/sphere-label";
import { Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import { SphereTextLink } from "@components/common/sphere-text-link";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useToast } from "@hooks/use-toast";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useAuthContext } from "@context-providers/auth/auth-context";
import { FaroSimpleTextField } from "@components/common/faro-text-field/faro-simple-text-field";
import {
  isValidCurrentPassword,
  isValidRepeatNewPassword,
} from "@pages/account-settings/sections/account-change-password/change-password-utils";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { PasswordInputButton } from "@pages/account-settings/sections/account-change-password/password-input-button";
import { PasswordValidationChecker } from "@faro-lotv/flat-ui";
import { FaroTextButton } from "@components/common/faro-text-button";
import {
  DEFAULT_BOLD_TEXT_FONT_WEIGHT,
  DEFAULT_INPUT_FONT_SIZE,
  withEllipsis,
} from "@styles/common-styles";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { UserProfile } from "@utils/track-event/track-event-list";

/** Message from the backend when the current password is incorrect */
const INCORRECT_PASSWORD_MSG = "Username/password do not match.";

/** Possible error messages */
enum ErrorMessages {
  currentPassword = "Password is incorrect",
  newPassword2 = "Input does not match new password",
}

interface Props {
  /** Email of the current user */
  email?: string;
}

/** Renders the change password button and dialog */
export function AccountChangePassword({ email }: Props): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const { showToast } = useToast();
  const { handleErrorWithToast } = useErrorContext();
  const { logout } = useAuthContext();
  const { trackEvent } = useTrackEvent();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isResetPassword, setIsResetPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isResetSuccess, setIsResetSuccess] = useState<boolean>(false);

  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] =
    useState<boolean>(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] =
    useState<boolean>(false);
  const [isNewPassword2Visible, setIsNewPassword2Visible] =
    useState<boolean>(false);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPassword2, setNewPassword2] = useState<string>("");

  const [isCurrentPasswordError, setIsCurrentPasswordError] =
    useState<boolean>(false);
  const [isNewPassword2Error, setIsNewPassword2Error] =
    useState<boolean>(false);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);

  const confirmText = useMemo(() => {
    if (isResetPassword) {
      return isResetSuccess ? "Sign out" : "Reset password";
    } else {
      return "Change password";
    }
  }, [isResetPassword, isResetSuccess]);

  // Handles form validation
  useEffect(() => {
    const isValid =
      isValidCurrentPassword(currentPassword) &&
      isValidRepeatNewPassword(newPassword2, newPassword) &&
      isPasswordValid;

    setIsValidForm(isValid);
  }, [currentPassword, isPasswordValid, newPassword, newPassword2]);

  // Handles error in `current password` field
  useEffect(() => {
    // Reset error if user deletes input
    if (!currentPassword) {
      return setIsCurrentPasswordError(false);
    }

    setIsCurrentPasswordError(!isValidCurrentPassword(currentPassword));
  }, [currentPassword]);

  // Handles error in `repeat new password` field
  useEffect(() => {
    if (newPassword2.length >= newPassword.length) {
      setIsNewPassword2Error(
        !isValidRepeatNewPassword(newPassword2, newPassword)
      );
    }
  }, [newPassword, newPassword2]);

  function resetState(): void {
    setIsDialogOpen(true);
    setIsResetPassword(false);
    setIsLoading(false);
    setIsResetSuccess(false);

    setIsCurrentPasswordVisible(false);
    setIsNewPasswordVisible(false);
    setIsNewPassword2Visible(false);

    setCurrentPassword("");
    setNewPassword("");
    setNewPassword2("");

    setIsCurrentPasswordError(false);
    setIsNewPassword2Error(false);
    setIsValidForm(false);
  }

  function onOpen(): void {
    resetState();
  }

  function onForgotPassword(): void {
    setIsResetPassword(true);
  }

  async function resetPassword(): Promise<void> {
    if (!email) {
      return;
    }

    trackEvent({
      name: UserProfile.updateProfile,
      props: { attribute: "reset password" },
    });

    setIsLoading(true);

    try {
      await coreApiClient.V1.SDB.resetUserPassword({
        // eslint-disable-next-line @typescript-eslint/naming-convention -- naming defined by backend
        resetpw_email: email,
      });

      setIsResetSuccess(true);

      showToast({
        message: "Password reset email sent",
        type: "success",
      });
    } catch (error) {
      handleErrorWithToast({
        id: `resetUserPassword-${Date.now().toString()}`,
        title: "Could not reset password. Please try again",
        error,
      });
    }

    setIsLoading(false);
  }

  async function changePassword(): Promise<void> {
    setIsLoading(true);

    trackEvent({
      name: UserProfile.updateProfile,
      props: { attribute: "change password" },
    });

    try {
      await coreApiClient.V1.SDB.setNewPassword({
        /* eslint-disable @typescript-eslint/naming-convention -- naming defined by backend */
        setnewpw_current_pw: currentPassword,
        setnewpw_pw: newPassword,
        setnewpw_pw_2: newPassword2,
        /* eslint-enable @typescript-eslint/naming-convention */
      });

      closeDialog();

      showToast({
        message: "Password changed successfully",
        type: "success",
      });
    } catch (error) {
      handleErrorWithToast({
        id: `setNewPassword-${Date.now().toString()}`,
        title: "Could not change password. Please try again",
        error,
      });

      // Attempt to determine if backend error was due to an incorrect password.
      // If incorrect then set the error state for the input.
      const errorMarkup = getErrorDisplayMarkup(error);
      if (errorMarkup.includes(INCORRECT_PASSWORD_MSG)) {
        setIsCurrentPasswordError(true);
      }
    }

    setIsLoading(false);
  }

  function closeDialog(): void {
    setIsDialogOpen(false);
  }

  function onConfirm(): void {
    if (isResetPassword) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      isResetSuccess ? logout() : resetPassword();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Please review lint error
      changePassword();
    }
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{
        width: "100%",
        ...withEllipsis,
      }}
    >
      <var>●●●●●●●●●●●●●●</var>

      {/* Button to open the dialog */}
      <FaroTextButton
        sx={{
          fontSize: DEFAULT_INPUT_FONT_SIZE,
          fontWeight: DEFAULT_BOLD_TEXT_FONT_WEIGHT,
        }}
        onClick={onOpen}
      >
        Change
      </FaroTextButton>

      <FaroDialog
        title="Password"
        open={isDialogOpen}
        confirmText={confirmText}
        isConfirmLoading={isLoading}
        isConfirmDisabled={isResetPassword ? false : !isValidForm}
        onConfirm={onConfirm}
        isSuccessMessage={isResetSuccess}
        onClose={closeDialog}
      >
        {/* Change password */}
        {!isResetPassword && (
          <Grid width="400px" maxWidth="100%">
            {/* Current password */}
            <Stack mb={SPACE_ELEMENTS_OF_MODAL}>
              <SphereLabel
                title="Current password"
                isRequired
                rightSideComponent={
                  email ? (
                    <SphereTextLink
                      text="Forgot password?"
                      onClick={onForgotPassword}
                    />
                  ) : undefined
                }
              />
              <FaroSimpleTextField
                value={currentPassword}
                type={isCurrentPasswordVisible ? "text" : "password"}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCurrentPassword(event.target.value);
                }}
                autoFocus={true}
                placeholder="Enter password"
                size="small"
                error={isCurrentPasswordError}
                helperText={
                  isCurrentPasswordError ? ErrorMessages.currentPassword : null
                }
                InputProps={{
                  endAdornment: (
                    <PasswordInputButton
                      isVisible={isCurrentPasswordVisible}
                      onClick={() =>
                        setIsCurrentPasswordVisible(!isCurrentPasswordVisible)
                      }
                    />
                  ),
                }}
              />
            </Stack>

            {/* New password */}
            <Stack mb={"12px"}>
              <SphereLabel title="New password" isRequired />
              <FaroSimpleTextField
                value={newPassword}
                type={isNewPasswordVisible ? "text" : "password"}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewPassword(event.target.value);
                }}
                autoFocus={false}
                placeholder="Enter new password"
                size="small"
                error={!isPasswordValid && !!newPassword.length}
                InputProps={{
                  endAdornment: (
                    <PasswordInputButton
                      isVisible={isNewPasswordVisible}
                      onClick={() =>
                        setIsNewPasswordVisible(!isNewPasswordVisible)
                      }
                    />
                  ),
                }}
              />

              <Stack marginTop={"12px"}>
                <PasswordValidationChecker
                  password={newPassword}
                  onValidationChange={setIsPasswordValid}
                />
              </Stack>
            </Stack>

            {/* Repeat new password */}
            <Stack>
              <SphereLabel title="Repeat new password" isRequired />
              <FaroSimpleTextField
                value={newPassword2}
                type={isNewPassword2Visible ? "text" : "password"}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNewPassword2(event.target.value);
                }}
                autoFocus={false}
                placeholder="Re-enter new password"
                size="small"
                error={isNewPassword2Error}
                helperText={
                  isNewPassword2Error ? ErrorMessages.newPassword2 : null
                }
                InputProps={{
                  endAdornment: (
                    <PasswordInputButton
                      isVisible={isNewPassword2Visible}
                      onClick={() =>
                        setIsNewPassword2Visible(!isNewPassword2Visible)
                      }
                    />
                  ),
                }}
              />
            </Stack>
          </Grid>
        )}

        {/* Reset password */}
        {isResetPassword && !isResetSuccess && (
          <Grid width="400px" maxWidth="100%" mt={SPACE_ELEMENTS_OF_MODAL}>
            <Stack mb={SPACE_ELEMENTS_OF_MODAL}>
              To reset your password we will send you an email with a reset
              link.
            </Stack>
            <Stack>
              After clicking on the "Reset password" button you will be required
              to sign out from the application.
            </Stack>
          </Grid>
        )}

        {/* Sign out after password reset */}
        {isResetPassword && isResetSuccess && (
          <Grid width="400px" maxWidth="100%" mt={SPACE_ELEMENTS_OF_MODAL}>
            <Stack mb={SPACE_ELEMENTS_OF_MODAL}>
              Check your email inbox and follow the steps to reset your
              password.
            </Stack>
            <Stack>Click on the button to sign out.</Stack>
          </Grid>
        )}
      </FaroDialog>
    </Grid>
  );
}
